import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const FooterWrapper = styled.section`
  h2 {
    font-size: 16px;
  }
  .socialInfos {
    margin-left: auto;

    @media (max-width: 580px) {
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }

  .ListIcon {
    margin-right: 16px;
  }
  padding: 0px 0 80px;
  @media (max-width: 480px) {
    padding: 0px 0 50px;
  }
`

const List = styled.ul``

const ListItem = styled.li`
  img {
    &:first-child {
      align-self: start;
      margin-top: 4px;
    }
  }
  display: flex;
  margin-bottom: 16px;

  p {
    color: #717171;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  a {
    color: #717171;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 0 24px -5px;

  li {
    margin-right: 16px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet("colors.lightText", "#717171")};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 28px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet("colors.secondaryColor", "#297E7F")};
      }
    }
  }
`

export { List, ListItem }

export default FooterWrapper
