import FooterWrapper, {
  List,
  ListItem,
  SocialList,
} from "./contactDetails.style"
import { graphql, useStaticQuery } from "gatsby"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import Heading from "../../components/Heading"
import { Icon } from "react-icons-kit"
import Image from "../../components/Image"
import PropTypes from "prop-types"
import React from "react"
import { facebook } from "react-icons-kit/fa/facebook"
import { instagram } from "react-icons-kit/fa/instagram"

const Footer = ({ row, col, colOne, titleStyle, textStyle, LinksStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      elegantJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            phone
            callNumber
            icon {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <Container>
        <Fade up delay={100}>
          <Box className="row" {...row}>
            <Box {...colOne}>
              <>
                {Data.elegantJson.menuWidget.map(widget => (
                  <Box className="col" {...col} key={widget.id}>
                    <Heading content={widget.title} {...titleStyle} />
                    <List>
                      {widget.menuItems.map(item => {
                        return (
                          <ListItem key={`list__item-${item.id}`}>
                            <Image
                              src={item.icon.publicURL}
                              alt=""
                              className="ListIcon"
                            />
                            {item.text === null ? (
                              <a
                                href={`tel:${item.callNumber}`}
                                className="ListItem"
                              >
                                {item.phone}
                              </a>
                            ) : (
                              <p className="ListItem">{item.text}</p>
                            )}
                          </ListItem>
                        )
                      })}
                    </List>
                  </Box>
                ))}
                {/* <Box className="socialInfos">
                  <Heading content={"Follow us"} {...titleStyle} />
                  <SocialList>
                    <li className="facebook">
                      <a
                        href="https://www.facebook.com/elegantbeautyclinic"
                        aria-label="social share link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon icon={facebook} />
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/elegantbeautyclinic/"
                        aria-label="social share link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon icon={instagram} />
                      </a>
                    </li>
                  </SocialList>
                </Box> */}
              </>
            </Box>
            {/* End of footer List column */}
          </Box>
        </Fade>
      </Container>
    </FooterWrapper>
  )
}

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  LinksStyle: PropTypes.object,
}

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // Footer col two style
  colOne: {
    width: ["100%"],
    flexBox: true,
    justifyContent: "space-around",
    flexWrap: ["wrap", "wrap", "wrap", "wrap", "nowrap"],
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "25%", "25%"],

    mr: ["30px", "40px"],
    mb: ["30px", "0px"],
  },
  // widget title default style
  titleStyle: {
    color: "#333333",
    fontSize: "18px",
    fontWeight: "700",
    fontFamily: "Mulish",
    letterSpacing: "0.96px",
    mb: "24px",
  },
  // widget title default style
  LinksStyle: {
    color: "#333333",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Mulish",
    letterSpacing: "0.96px",
    mb: "16px",
  },
  // widget text default style
  textStyle: {
    color: "#707070",
    fontSize: "12px",
    mb: "0px",
    mr: "0px",
    fontWeight: "100",
  },
}

export default Footer
