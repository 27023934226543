import React from "react"

import Contact from "../containers/Contact"
import ContactDetails from "../containers/ContactDetails"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"

const ContactPage = () => {
  return (
    <>
      <SEO
        title="Contact"
        shortDesc="Elegant Beauty Clinic"
        description="Contact us to book an appointment at one of our clinics in Bournemouth, Poole or Southampton. Call Shikha on 07817 594 092 or complete online form here."
      />
      <Layout>
        <ContentWrapper>
          <PageTitle
            title="We can’t wait to see you"
            subtitle="Contact Elegant"
          />
          <Contact />
          <ContactDetails />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default ContactPage
